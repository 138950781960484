<template>
  <DailyAndMonthlyKpi
    :asup-id="asup.id"
    :timezone="asup.timezone"
    :get-kpi-data="getKpiDataFunc()"
    day-card-title="Job Success Rate per day"
    day-chart-y-axis-title="Job Count"
    month-card-title="Job Success Rate per month"
    month-chart-y-axis-title="Job Count"
  />
</template>

<script>
import DailyAndMonthlyKpi from '@/components/kpi/DailyAndMonthlyKpi.vue'
import VeeamService from '@/service/veeam.service'

export default {
  components: {
    DailyAndMonthlyKpi,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getKpiDataFunc() {
      return VeeamService.getJobKpiAsync
    },
  },
}
</script>

<template>
  <div>
    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Months', 'last6Months', 'thisYear', 'last2Years', 'last3Years', 'last5Years']"
            :timezone="asup.timezone"
          />
        </b-form-group>
      </b-form>
    </b-card>

    <b-row>
      <b-col>
        <b-overlay :show="isDailyKpiLoading">
          <b-card>
            <template #header>
              <b-card-title>Job Success Rate per day</b-card-title>
            </template>

            <apexchart
              type="area"
              :height="300"
              :options="dailyKpiChartOptions"
              :series="dailyKpiChartSeries"
            />

            <div
              class="table-responsive mt-2"
            >
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Succeeded</th>
                    <th>Failed</th>
                    <th>Success Rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(kpi, index) in pagedDailyKpi"
                    :key="index"
                  >
                    <td>{{ kpi.dateTime | formatDate }}</td>
                    <td>{{ kpi.succeededCount + kpi.activeCount + kpi.warningCount }}</td>
                    <td>{{ kpi.failedCount }}</td>
                    <td>
                      <template v-if="kpi.succeededCount + kpi.activeCount + kpi.warningCount + kpi.failedCount > 0">
                        {{ (100 - (kpi.failedCount * 100 / (kpi.succeededCount + kpi.activeCount + kpi.warningCount + kpi.failedCount))).toFixed(4) }}%
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="dailyKpi.length > dailyKpiPerPage"
                v-model="dailyKpiCurrentPage"
                class="mt-1"
                :total-rows="dailyKpi.length"
                :per-page="dailyKpiPerPage"
              />
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col>
        <b-overlay :show="isMonthlyKpiLoading">
          <b-card>
            <template #header>
              <b-card-title>Job Success Rate per month</b-card-title>
            </template>

            <apexchart
              type="area"
              :height="300"
              :options="monthlyKpiChartOptions"
              :series="monthlyKpiChartSeries"
            />
            <div
              class="table-responsive mt-2"
            >
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th>Year / Month</th>
                    <th>Succeeded</th>
                    <th>Failed</th>
                    <th>Success Rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(kpi, index) in orderedMonthlyKpi"
                    :key="index"
                  >
                    <td>{{ $moment(kpi.dateTime).format('YYYY MMMM') }}</td>
                    <td>{{ kpi.succeededCount + kpi.activeCount + kpi.warningCount }}</td>
                    <td>{{ kpi.failedCount }}</td>
                    <td>
                      <template v-if="kpi.succeededCount + kpi.activeCount + kpi.warningCount + kpi.failedCount > 0">
                        {{ (100 - (kpi.failedCount * 100 / (kpi.succeededCount + kpi.activeCount + kpi.warningCount + kpi.failedCount))).toFixed(4) }}%
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="monthlyKpi.length > monthlyKpiPerPage"
                v-model="monthlyKpiCurrentPage"
                class="mt-1"
                :total-rows="monthlyKpi.length"
                :per-page="monthlyKpiPerPage"
              />
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import {
  BRow, BCol, BCard, VBPopover, BForm, BFormGroup, BOverlay, BPagination, BCardTitle,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import VeeamService from '@/service/veeam.service'
import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BOverlay,
    BPagination,
    DateRangePicker,
    BCardTitle,
    apexchart: VueApexCharts,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        range: 'thisYear',
      },
      dailyKpi: [],
      monthlyKpi: [],
      isDailyKpiLoading: false,
      isMonthlyKpiLoading: false,
      dailyKpiCurrentPage: 1,
      dailyKpiPerPage: 10,
      monthlyKpiCurrentPage: 1,
      monthlyKpiPerPage: 10,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    orderedDailyKpi() {
      return this.dailyKpi
        .concat()
        .sort((a, b) => (this.$moment(a.dateTime).toISOString() > this.$moment(b.dateTime) ? -1 : 1))
    },
    orderedMonthlyKpi() {
      return this.monthlyKpi
        .concat()
        .sort((a, b) => (this.$moment(a.dateTime).toISOString() > this.$moment(b.dateTime) ? -1 : 1))
    },
    pagedDailyKpi() {
      return this.orderedDailyKpi.slice((this.dailyKpiCurrentPage - 1) * this.dailyKpiPerPage, this.dailyKpiCurrentPage * this.dailyKpiPerPage)
    },
    pagedMonthlyKpi() {
      return this.orderedMonthlyKpi.slice((this.monthlyKpiCurrentPage - 1) * this.monthlyKpiPerPage, this.monthlyKpiCurrentPage * this.monthlyKpiPerPage)
    },
    dailyKpiChartSeries() {
      const now = moment.utc()
      const beforeThan = now.startOf('day') // get only full days

      return [
        {
          name: 'Succeeded',
          data: this.dailyKpi
            .filter(x => moment.utc(x.dateTime) < beforeThan) // get only full days
            .map(x => ([this.$moment(x.dateTime).tz(this.asup.timezone).toISOString(), x.succeededCount])),
        },
        {
          name: 'Failed',
          data: this.dailyKpi
            .filter(x => moment.utc(x.dateTime) < beforeThan)
            .map(x => ([this.$moment(x.dateTime).tz(this.asup.timezone).toISOString(), x.failedCount])),
        },
      ]
    },
    monthlyKpiChartSeries() {
      const now = moment.utc()
      const beforeThan = now.startOf('month') // get only full months

      return [
        {
          name: 'Succeeded',
          data: this.monthlyKpi
            .filter(x => moment.utc(x.dateTime) < beforeThan)
            .map(x => ([x.dateTime, x.succeededCount])),
        },
        {
          name: 'Failed',
          data: this.monthlyKpi
            .filter(x => moment.utc(x.dateTime) < beforeThan)
            .map(x => ([x.dateTime, x.failedCount])),
        },
      ]
    },
    dailyKpiChartOptions() {
      const self = this
      return {
        chart: {
          height: 350,
          type: 'area',
        },
        colors: [$themeColors.success, $themeColors.danger],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          title: {
            text: 'SaveSet Job Count',
            align: 'left',
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            // eslint-disable-next-line no-unused-vars
            formatter(value, timestamp, opts) {
              return self.$moment(timestamp).format('DD.MM')
            },
          },
        },
        tooltip: {
          x: {
            formatter(val) {
              return self.$moment(val).format('L')
            },
          },
        },
      }
    },
    monthlyKpiChartOptions() {
      const self = this
      return {
        chart: {
          height: 350,
          type: 'area',
        },
        colors: [$themeColors.success, $themeColors.danger],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          title: {
            text: 'SaveSet Job Count',
            align: 'left',
          },
        },
        xaxis: {
          type: 'datetime',
          datetimeUTC: false,
          labels: {
            // eslint-disable-next-line no-unused-vars
            formatter(value, timestamp, opts) {
              return self.$moment(timestamp).format('MMM YY')
            },
          },
        },
        tooltip: {
          x: {
            formatter(val) {
              return self.$moment(val).format('MMMM YYYY')
            },
          },
        },
      }
    },
  },
  beforeMount() {
    this.$watch('timeRange', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isDailyKpiLoading = true
      VeeamService.getJobKpiAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        aggregateMonthly: false,
      }, { disableTenantFilter: true })
        .then(result => {
          this.dailyKpi = result.items
        })
        .finally(() => {
          this.isDailyKpiLoading = false
        })

      this.isMonthlyKpiLoading = true
      VeeamService.getJobKpiAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        aggregateMonthly: true,
      }, { disableTenantFilter: true })
        .then(result => {
          this.monthlyKpi = result.items
        })
        .finally(() => {
          this.isMonthlyKpiLoading = false
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
